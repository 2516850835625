import API from "../utils/axios";
import isAuthed from "../utils/isAuthed";
import { getTokenFromLocalStorage } from "../utils/local-storage";

function getFreshHeaders() {
  return {
    "Content-Type": "application/json",
    authorization: `Token ${getTokenFromLocalStorage()} `,
  };
}

export function listExperts() {
  if (isAuthed()) {
    return API.get(`/experts?status=approved`, { headers: getFreshHeaders() });
  } else {
    return API.get(`/experts/public?status=approved`);
  }
};

export function getExpert(expertId) {
  if (isAuthed()) {
    return API.get(`/experts/${expertId}`, { headers: getFreshHeaders() });
  } else {
    return API.get(`/experts/${expertId}/public`);
  }
};

export async function getAnswersByExpert(expertId) {
  return API.get(`/answers/by-expert/${expertId}`, { headers: getFreshHeaders() });
};

export async function followExpert(expertId) {
  return API.post(`/experts/${expertId}/follow`, null, { headers: getFreshHeaders() });
};

export async function unfollowExpert(expertId) {
  return API.delete(`/experts/${expertId}/follow`, { headers: getFreshHeaders() });
};

export default {
  listExperts,
  getExpert,
  getAnswersByExpert,
  followExpert,
  unfollowExpert,
};

import API from "../utils/axios";
import isAuthed from "../utils/isAuthed";
import { headers } from "../utils/header";

export function getFollowedExperts(data) {
  return API.get("/me/followed-experts", { headers });
};

export default {
  getFollowedExperts,
};

import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { truncate } from "lodash";
import like from "./assets/images/like.svg";
import answered from "./assets/images/message.svg";
import education from "./assets/images/education.svg";
import { Link } from "gatsby";
import { Fade } from "react-awesome-reveal";
import Connect from "./connect";
import ReactHtmlParser from "react-html-parser";
export default function FeaturedExpert({ content }) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    typeof window !== "undefined" &&
      localStorage.setItem("featured", JSON.stringify(content));
  }, [content]);
  const [choosebox, setchoosebox] = useState(false);
  const [expertData, setExpert] = useState({});

  const ExpertActions = ({ expert }) => (
    <Button
      variant="blue"
      href=""
      onClick={() => {
        setchoosebox(true);
        setExpert(expert);
      }}
      className="connect"
    >
      Connect
    </Button>
  );

  return (
    <div className="FeaturedExpert">
      <div className="content">
        <h3>FEATURED PARTNERS</h3>
        <Fade direction="top">
          <Slider {...settings}>
            {content.nodes.map((x, y) => {
              const profileLink =
                x.metadata.expert === 34626
                  ? `partners/profile?id=${x.metadata.expert}`
                  : `partners/member?id=${x.metadata.expert}`;
              const truncatedBio = truncate(x.metadata.description, {
                length: 350,
                separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
              });

              return (
                <div className="box">
                  <Row>
                    <Col md={12} lg={4}>
                      <img src={x.metadata.media.url} className="profilepic" />
                      <div className="counts d-none d-lg-block pt-2">
                        {/*     <div className="answered">
                          <img src={answered} />
                          <span>
                            <strong>{x.questionCount}</strong> Questions
                            Answered
                          </span>
                        </div> */}
                        {/* <div className="thankx">
                          <img src={like} />
                          <span>
                            <strong>{x.PatientThankxCount}</strong> Patients said Thanks
                          </span>
                        </div> */}
                      </div>
                    </Col>
                    <Col md={12} lg={8}>
                      <div className="details">
                        <div className="box_sched">
                          <Link to={profileLink}>
                            <h2>{x.metadata.name}</h2>
                          </Link>
                        </div>
                        <h3>{x.metadata.designation}</h3>
                        <div className="location">{x.metadata.location}</div>

                        <div className="education-details">
                          <img src={education} />
                          <span>{x.metadata.education}</span>
                        </div>

                        <div className="featured_social">
                          <ExpertActions expert={x} />
                        </div>

                        {/*<div className="counts d-lg-none">
                          {/* <div className="answered">
                            <span>
                              <strong>{x.questionCount}</strong>
                            </span>
                            <img src={answered} />
                          </div> */}
                        {/* <div className="thankx">
                            <span>
                              <strong>{x.PatientThankxCount}</strong>
                            </span>
                            <img src={like} />
                          </div> 
                        </div>*/}
                        <p className="expert-bio">
                          {ReactHtmlParser(truncatedBio)}{" "}
                          <Link to={profileLink} className="read-more-link">
                            (read more)
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Slider>
        </Fade>
      </div>
      <Connect
        status={choosebox}
        setchoosebox={setchoosebox}
        expertData={expertData}
      />
    </div>
  );
}

import React, { useState } from "react";
import answered from "./assets/images/message.svg";
import like from "./assets/images/like.svg";
import plus from "./assets/images/plus.svg";
import brief from "./assets/images/briefcase.svg";
import user from "./assets/images/add.png";
import { Link } from "gatsby";
import { Row, Col, Button } from "react-bootstrap";
import isAuthed from "../../api/utils/isAuthed";
import Connect from "./connect.js";
export default function Results({ content, onToggleFollow }) {
  function handleClickFollow(expert) {
    onToggleFollow(!expert.follower, expert.id);
  }
  const [choosebox, setchoosebox] = useState(false);
  const [expertData, setExpert] = useState({});

  const ExpertActions = ({ expert }) => (
    <>
      {/* <Button
        variant="blue-light"
        active={expert.follower}
        disabled={!isAuthed()}
        onClick={() => handleClickFollow(expert)}
        className="follow"
      >
        {expert.follower ? "Unfollow" : "Follow"}
      </Button> */}
      {
        <div
          variant="blue"
          href=""
          onClick={() => {
            setchoosebox(true);
            setExpert(expert);
          }}
          className="connect-special-expert"
        >
          Connect
        </div>
      }
    </>
  );

  return (
    <div className="Results">
      {content.map((expert, index) => (
        <div key={index === 3 ? "ad-1" : index === 5 ? "ad-2" : expert.id}>
          {index === 5 && (
            <div className="addbox2">
              <div>
                <h4>Want to join our Fertility Network?</h4>
                <h5>If you’re board certified, we’d love to hear from you. </h5>
                <Link to="https://app.medanswers.com/sign-up/expert" class="stretched-link">
                  <button type="button">Connect</button>
                </Link>
              </div>
              <img src={user} />
            </div>
          )}

          <article className="box">
            <div className="main-box">
              <img
                src={expert.url_avatar}
                className="expert-image d-none d-md-block"
                alt={`${expert.first_name} ${expert.last_name}`}
              />

              <div className="info">
                <div className="d-flex">
                  <img
                    src={expert.url_avatar}
                    className="expert-image d-md-none"
                    width={85}
                    height={85}
                    alt={`${expert.first_name} ${expert.last_name}`}
                  />
                  <div>
                    <h3>
                      <Link
                        to={`partners/profile?id=${expert.id}`}
                        className="stretched-link"
                      >
                        {expert.first_name} {expert.last_name}
                        {expert.degrees.length > 0 &&
                          `, ${expert.degrees
                            .map(({ description }) => description)
                            .join(", ")}`}
                      </Link>
                    </h3>
                    <div className="expert-location">
                      <h3 className="seocheckh3">
                        {" "}
                        {expert.city}, {expert.country}
                      </h3>
                    </div>
                    {
                      <div className="social d-block d-md-none">
                        <ExpertActions expert={expert} />
                      </div>
                    }
                  </div>
                </div>

                <div className="expert-specialities">
                  <img src={plus} />
                  <h3 className="seocheckh3">
                    {" "}
                    {expert.specialities
                      .map(({ description }) => description)
                      .join(", ")}
                  </h3>
                </div>
                <div className="expert-affiliation">
                  <img src={brief} />
                  <h3 className="seocheckh3"> {expert.affiliation}</h3>
                </div>
              </div>

              <div className="social d-none d-md-flex">
                {
                  <div className="social d-block ">
                    <ExpertActions expert={expert} />
                  </div>
                }
              </div>
            </div>

            {/*<div className="counts">
              <ul className="list-unstyled">
                <li>
                  {expert.answers_count} <img src={answered} />
                </li>

                <li>
                  200 <img src={like} />
                </li>
              </ul>
            </div> */}
          </article>
        </div>
      ))}
      <Connect
        status={choosebox}
        setchoosebox={setchoosebox}
        expertData={expertData}
      />
    </div>
  );
}

import API from "../utils/axios";
var all_sub_topics;
export const allCategories = async () => {
  all_sub_topics = [];
  const response = await API.get("/topics");

  const sub_ids = response.data.map((x) => x.id);

  const check_all_ids = new Promise((res, rej) => {
    sub_ids.map((data, count) => {
      API.get("/topics/" + data + "/subtopics").then((x) => {
        all_sub_topics.push(x);

        if (count === sub_ids.length - 1) {
          res();
        }
      });
    });
  });

  await check_all_ids;
  var final_output_cat = [];
  const final_output = all_sub_topics.map((x) => {
    x.data.map((f) => {
      final_output_cat.push(f);
    });
  });

  return final_output_cat;
};

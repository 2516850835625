import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

export default function RadioButtons({ check, title, content, onChange }) {
  const [value, setValue] = React.useState('all');

  const handleChange = (event) => {
    let value = event.target.value;
    if (value !== 'all') value = parseInt(value, 10);
    setValue(value);
    onChange(value);
  };

  return (
    <div className={check + " Radiobuttons"}>
      <div className="content">
        <h4>{title}</h4>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Subtopic"
            value={value}
            onChange={handleChange}
          >
            {content.map((item) => (
              <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.description} />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
}

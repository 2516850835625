import React, { useState } from "react";
import { Dropdown, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import settings from "./assets/images/settings.png";

export default function Controls({
  specialities,
  onSelectSpeciality,
  onChangeSearch,
  openmobileoptions,
}) {
  const [specialityText, setSpecialityText] = useState("Select Specialty");
  const [searchQuery, setSearchQuery] = useState("");

  function handleSelectSpeciality(speciality) {
    setSpecialityText(speciality.description);
    onSelectSpeciality(speciality.id);
  }

  function handleChangeSearch(e) {
    const query = e.target.value;
    setSearchQuery(query);
    onChangeSearch(query);
  }

  return (
    <div className="controls">
      <Dropdown onSelect={console.log}>
        <Dropdown.Toggle id="dropdown-basic">{specialityText}</Dropdown.Toggle>
        <Dropdown.Menu>
          {specialities.map((speciality) => (
            <Dropdown.Item
              key={speciality.id}
              onSelect={() => handleSelectSpeciality(speciality)}
            >
              {speciality.description}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
        {/* <img
          className="configuration-slider"
          src={settings}
          onClick={openmobileoptions}
          /> */}
      </Dropdown>

      <InputGroup className="mb-3">
        <FormControl
          value={searchQuery}
          onChange={handleChangeSearch}
          placeholder="City or Name"
          aria-label="City or Name"
          aria-describedby="basic-addon2"
        />
        <InputGroup.Append>
          <InputGroup.Text id="basic-addon2">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
}
